import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import BottomBar from "../../component/appBar/BottomBar";
import TransferProcess from "../../component/home/TransferProcess";
import DebtList from "../../component/home/debt/DebtList";
import CashPayblesList from "../../component/home/cashpaybles/CashPayblesList";
import MoneyTransferList from "../../component/home/transfer/MoneyTransferList";
const Account = () => {
  const [key, setKey] = useState<string>('alacaklar');
  return (
    <div className="d-flex flex-column min-vh-100">
      <WhiteTopBar text="Hesap" />
      <Container>
        <Row className="mt-3">
          <Col
            className="text-center"
            style={{
              borderBottom: "1px solid #d1cbcb",
              borderRight: "1px solid #d1cbcb",
              padding: "10px",
              borderRadius: "0 0 0 0",
            }}
          >
            <a
              href="#alacaklar"
              className="text-decoration-none"
              onClick={() => setKey('alacaklar')}
              style={{ color: "#125f3b" }}
            >
              Alacaklar
            </a>
          </Col>
          <Col
            className="text-center"
            style={{
              borderBottom: "1px solid #d1cbcb",
              borderLeft: "1px solid #d1cbcb",
              borderRight: "1px solid #d1cbcb",
              padding: "10px",
              borderRadius: "0 0 0 0",
            }}
          >
            <a
              href="#borclar"
              className="text-decoration-none"
              onClick={() => setKey('borclar')}
              style={{ color: "#f31337" }}
            >
              Borçlar
            </a>
          </Col>
          <Col
            className="text-center"
            style={{
              borderBottom: "1px solid #d1cbcb",
              borderLeft: "1px solid #d1cbcb",
              padding: "10px",
              borderRadius: "0 0 0 0",
            }}
          >
            <a
              href="#transferler"
              className="text-decoration-none"
              onClick={() => setKey('transferler')}
              style={{ color: "#2655ad" }}
            >
              Transferler
            </a>
          </Col>
        </Row>
        <Row >
        <Col>
            {key === 'borclar' && <DebtList />}
            {key === 'alacaklar' && <CashPayblesList />}
            {key === 'transferler' && <MoneyTransferList />}
          </Col>
        </Row>
      </Container>
      <BottomBar />
    </div>
  );
};

export default Account;
