import BottomBar from "../../component/appBar/BottomBar";
import TopBar from "../../component/appBar/TopBar";
import { Form, Row, Col, Container, InputGroup } from "react-bootstrap";
import DonutGraffice from "../../component/home/DonutGraffice";
import CustomerList from "../../component/home/CustomerList";
import { FaSearch } from "react-icons/fa";


const Home = () => {

  return (
    <div className="d-flex flex-column min-vh-100">
      <TopBar />
      <Container fluid className="flex-grow-1 px-0" >
        <Row className="justify-content-center align-items-center h-100 mx-0">
          <Col xs={12} md={6} className="d-flex justify-content-center px-0">
            <DonutGraffice />
          </Col>
        </Row>
        <Row>
          <Form.Text className="mb-4 ms-3"
            style={{ fontSize: "24px", fontWeight: "bold", color: "#2895fe" }}
          >
            Müşteriler
          </Form.Text>
          <Form.Group
            controlId="searchCustomer"
            className="mb-3"
            style={{
              maxWidth: "400px",
              borderRadius: "3rem",
              margin: "0 auto 0 0",
            }}
          >
            <InputGroup className="ms-3" style={{ maxWidth: "300px" }}>
              <Form.Control
                type="text"
                placeholder="Müşteri ara"
                style={{
                  height: "40px",
                  paddingRight: "40px",
                  borderRadius: "2rem",
                  backgroundColor: "#f6f9ff",
                }}
              />
              <InputGroup.Text style={{ border: "none" }}>
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="justify-content-center align-items-center h-100 mx-0">
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column align-items-center px-0"
          >
            <CustomerList />
          </Col>
        </Row>
      </Container>
      <BottomBar />
    </div>
  );
};

export default Home;