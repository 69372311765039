import React, { useState } from 'react';
import { FaHome, FaBook, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../sideBarr/sideBarr';
import { Container, Row, Col } from 'react-bootstrap';

const BottomBar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  return (
    <>
    <Container fluid className="fixed-bottom bg-light border-top py-2">
      <Row className="justify-content-around align-items-center">
        <Col className="text-center">
          <Link to="/" className="text-decoration-none text-dark">
            <FaHome size={24} className="d-block mx-auto mb-1"  style={{color:"#2895fe"}} />
            <span>Anasayfa</span>
          </Link>
        </Col>
        <Col className="text-center">
          <Link to="/home/account" className="text-decoration-none text-dark">
            <FaBook size={24} className="d-block mx-auto mb-1" style={{color:"#2895fe"}} />
            <span>Hesap</span>
          </Link>
        </Col>
        <Col className="text-center" onClick={toggleSidebar}>
          <div className="text-decoration-none text-dark">
            <FaBars size={24} className="d-block mx-auto mb-1"   style={{color:"#2895fe"}}/>
            <span>Diğer</span>
          </div>
        </Col>
      </Row>
    </Container>
    <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
  </>
    
  );
};

export default BottomBar;
