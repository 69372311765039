import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";

interface Customer {
  firstName: string;
  lastName: string;
  nakit: string;
  islem: string;
  vt: string;
  at: string;
}
interface Customers {
  customerName: string;
  customerSurname: string;
  debtAmount: string;
  debtCurrency:string;
  debtIssuanceDate: string;
  debtRepaymentDate: string;
}
interface CustomerCardProps {
  // customer: Customer;
  customer : Customers;
  islem: string;

}
const DebtCard: React.FC<CustomerCardProps> = ({ customer, islem }) => {
  const firstLetter = customer.customerName.charAt(0).toUpperCase();
  const formattedIssuanceDate = new Date(customer.debtIssuanceDate).toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedRepaymentDate = new Date(customer.debtRepaymentDate).toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  // if (customer.islem !== "verdim") {
  //   return null; // İşlem "verdim" değilse hiçbir şey render etme
  // }
  // const firstLetter = customer.firstName.charAt(0).toUpperCase();
  // let islemColor: string;
  // if (customer.islem === "verdim") {
  //   islemColor = "#10853e";
  // } else {
  //   islemColor = "#f21236";
  // }

  return (
    <Container
      className="my-4 p-4 position-relative"
      style={{
        borderRadius: "7px",
        backgroundColor: "#ffff",
        border: "2px solid #f31137",
        maxWidth: "530px",
        width: "100%",
        height: "6rem",
        margin: "0 auto",
      }}
    >
      <Row className="h-100 align-items-center">
        <Col
          xs="auto" // Genişliği otomatik ayarla
          className="d-flex align-items-center justify-content-center position-relative "
          style={{ marginRight: "-3rem" }}
        >
          <div
            style={{
              width: "3rem",
              height: "3rem",
              borderRadius: "50%",
              backgroundColor: "#f31137",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "2rem",
            }}
          >
            {firstLetter}
          </div>
        </Col>
        <Col>
          <Row>
            <Card.Body className="text-center">
              <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
                {customer.customerName} {customer.customerSurname}
              </Card.Title>
            </Card.Body>
          </Row>
          <Row className="d-flex justify-content-center ">
            <Col
              style={{ marginLeft: "30%" }}
              className="d-flex justify-content-between mt-4 ml-5"
            >
              <Card.Text className="text-center d-flex">
                <span style={{ marginRight: "0.5rem", fontSize: "1rem" }}>
                  V.T:
                </span>
                <span style={{ fontSize: "1rem" }}>{formattedIssuanceDate}</span>
              </Card.Text>
              <Card.Text className="text-center d-flex">
                <span
                  style={{
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                  }}
                >
                  A.T:
                </span>
                <span style={{ fontSize: "1rem" }}>{formattedRepaymentDate}</span>
              </Card.Text>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex flex-column ">
          <Row className="mb-2 d-flex ms-3 ">
            <FaEdit  style={{color: '#007bff'}}/>
          </Row>
          <Row className="mb-2 d-flex ms-3">
            <FaTrash style={{color:"#dc3545"}}/>
          </Row>
        </Col>
        <Col className="d-flex flex-column align-items-end mb-4 ml-auto">
          <Row style={{ right: "0" }} className="w-100 ">
            <Card.Text className="text-center" style={{ color: "#f31137" }}>
              <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
                {customer.debtAmount}{customer.debtCurrency}
              </Card.Title>
            </Card.Text>
          </Row>
          <Row style={{ right: "0" }} className="w-100">
            <Card.Text className="text-center" style={{ color: "#d5cbcc" }}>
              <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
                {islem}
              </Card.Title>
            </Card.Text>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DebtCard;
