import React from "react";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./AppRoutes";

const App: React.FC = () => {
  return (
    // <Router>
        <AppRoutes />
    // </Router>
  );
};

export default App;
