import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import DebtDetailCard from "./DebtDetailCard";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, TooltipItem, ChartOptions } from 'chart.js';
import { debtdetailCustomerList } from "../../../api/customer";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export interface Customers {
  type: string;
  customerName: string;
  customerSurname: string;
  debtAmount: string;
  debtCurrency: string;
  debtIssuanceDate: string;
  debtRepaymentDate: string;
  id: number;
  totalReceivables : number;
  toatlDebts: number;
  difference: number;
  }
  interface CustomerCardProps {
    customer: Customers;
    islemList: string;
  }
const DebtDetailList: React.FC<CustomerCardProps> = ({customer, islemList}) => {
  const [customs, setCustomers] = useState<Customers[]>([]);
  const userId = 1;
  const customerId = customer.id;

  let islemColorList: string;

  useEffect(() => {
    const fetchCustomers = async () => {
      try{
        const customerData = await debtdetailCustomerList({userId, customerId});
        setCustomers(customerData.data.combinedResult);
      }catch(error){
        console.error("müşteri işlemleri yüklenirken bir hata oldu");
      }
    };
    fetchCustomers();
  },[]);
  if(customer.type === "Debt"){
    islemColorList = "#10853e";
    islemList = "verdim"
}
else{
  islemColorList ="#f21236";
  islemList= "aldım"
}


  const customers = [
    {
      nakit: "500 ₺",
      islem: "verdim",
      saat: "15:20",
      tarih: "28.01.2000",
    },
    {
      nakit: "1000 ₺",
      islem: "aldım",
      saat: "15:20",
      tarih: "17.01.2000",
    },
    {
        nakit: "300 ₺",
        islem: "verdim",
        saat: "15:20",
        tarih: "17.01.2000",
      },
      {
        nakit: "600 ₺",
        islem: "verdim",
        saat: "15:20",
        tarih: "17.01.2000",
      },
      {
        nakit: "600 ₺",
        islem: "aldım",
        saat: "15:20",
        tarih: "17.01.2000",
      },
  ];
  const totalNakitVerdim = customers
    .filter((customer) => customer.islem === "aldım")
    .reduce((acc, curr) => acc + parseFloat(curr.nakit.replace(" ₺", "").replace(".", "").replace(",", ".")), 0);

    const totalNakitVerdim1 = customs
  // const totalNakitVerdim1 = customs
  //   .filter((customs) => customs.type === "Receivable")
  //   .reduce((acc, curr) => acc + parseFloat(curr.debtAmount.replace(" ₺", "").replace(".", "").replace(",", ".")), 0);
  // const totalNakitAldım1 = customs
  //   .filter((customs) => customs.type === "Debt")
  //   .reduce((acc, curr) => acc + parseFloat(curr.debtAmount.replace(" ₺", "").replace(".", "").replace(",", ".")), 0);

  const totalNakitAldim = customers
    .filter((customer) => customer.islem === "verdim")
    .reduce((acc, curr) => acc + parseFloat(curr.nakit.replace(" ₺", "").replace(".", "").replace(",", ".")), 0);


   let islemColor;
   let islem;
   const difference = Math.abs(totalNakitAldim - totalNakitVerdim);
   const differenceString = (customs.length > 0 && customs[0].difference !== undefined) 
   ? customs[0].difference.toString() 
   : '0';
 
 console.log(differenceString); 
  //  console.log("fark ekrana yazdırıldı: ", differences)
    if(totalNakitAldim > totalNakitVerdim){
      islemColor="#f21236";
      islem="Kalan Borcu";
    }
    else{
      islemColor="#10853e"
      islem = "Kalan Borcum";
    }

  const data = {
    labels: ["Aldım", "Verdim"], // Labels for the doughnut chart
    datasets: [
      {
        data: [totalNakitVerdim, totalNakitAldim],
        // data: [
        //   customers.filter((customer) => customer.islem === "verdim").length,
        //   customers.filter((customer) => customer.islem === "aldım").length,
        // ], 
        backgroundColor: ["#10853e", "#f21236"], // Colors for each section
        borderColor: "#fff",
        borderWidth: 2,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const, 
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    cutout: '50%',
  };
  return (
    <div className="d-flex flex-column align-items-center mt-10">
      <div style={{ width: "50%", maxWidth: "600px", marginBottom: "2rem" }}>
        <Doughnut data={data} options={options} />
        <div style={{ color:islemColor, fontSize: "2rem", fontWeight: "bold" , marginBottom:"1rem", display:"flex", right: 0,}}>
          <p>{islem} {differenceString} ₺</p>
        </div>
      </div>
      <Container
        className="mt-20"
        style={{
          marginBottom: "6rem",
          padding: "1rem",
          borderRadius: "4rem",
          border: "2px solid #2895fe", // Bordera rengi ve kalınlığı
          maxWidth: "100%", // Maksimum genişlik
          backgroundColor: "#fff", // Arka plan rengi
        }}
      >
        {customs.map((custom, index) => (
          <DebtDetailCard key={index} customer={custom} />
        ))}
               
      </Container>
    </div>

  );
};

export default DebtDetailList;
