// import React from "react";
// import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
// import "bootstrap/dist/css/bootstrap.min.css";

// const data = [
//   { name: "Red", value: 12 },
//   { name: "Blue", value: 19 },
//   { name: "Yellow", value: 3 },
//   { name: "Green", value: 5 },
//   { name: "Purple", value: 2 },
//   { name: "Orange", value: 3 },
// ];

// const BLUE_COLOR = "#36A2EB";
// const COLORS = [
//   "#FF6384",
//   "#36A2EB",
//   "#FFCE56",
//   "#4BC0C0",
//   "#9966FF",
//   "#FF9F40",
// ];

// const DonutGraffice = () => {
//   return (
//     <div className="d-flex justify-content-center">
//       <div style={{ width: "100%", maxWidth: "400px", position: "relative" }}>
//         <PieChart width={400} height={400}>
//           <Pie
//             data={data}
//             cx="50%"
//             cy="50%"
//             innerRadius={120}
//             outerRadius={160}
//             fill={BLUE_COLOR}
//             paddingAngle={5}
//             dataKey="value"
//           >
//             {data.map((entry, index) => (
//               <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//             ))}
//           </Pie>
//           <Tooltip />
//           <Legend />
//         </PieChart>
//         <div
//           className="container position-absolute"
//           style={{
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             textAlign: "center",
//           }}
//         >
//           <div className="row g-0">
//             <div className="col p-0">
//               <p style={{ margin: 0, color: "#125f3d" }}>Toplam Nakit</p>
//               <p style={{ margin: 0, color: "#125f3d" }}>1000&nbsp;₺</p>
//             </div>
//             <div className="col p-0">
//               <p style={{ margin: 0, color: "#f31137" }}>Toplam Borç</p>
//               <p style={{ margin: 0, color: "#f31137" }}>1000&nbsp;₺</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DonutGraffice;








































import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, Text } from "recharts";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUserFinancialSummary, homeUserCashAmount } from "../../api/customer";
import { useEffect, useState } from "react";

const data = [
  { name: "Red", value: 12 },
  { name: "Blue", value: 19 },
  { name: "Yellow", value: 3 },
  { name: "Green", value: 5 },
  { name: "Purple", value: 2 },
  { name: "Orange", value: 3 },
];

const BLUE_COLOR = "#36A2EB";
const COLORS = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#FF9F40",
];


const DonutGraffice = () => {
  // const [userCashAmount, setUserCashAmount] = useState<{
  //   userId: number,
  //   id: number,
  //   totalCash: number;
  //   cashCurrency: string;
  // } | null>(null);
  const [userAmount, setUserCashAmount] = useState<{
    totalCash: number;
    totalDebt: number;
  } | null>(null);
  const [error, setError] = useState('');
  useEffect(() => {
    const fetchUserCashAmount = async () => {
      try {
        const userId = 1; // Kullanıcı ID'sini buradan ayarlayabilirsiniz
        // const amount = await homeUserCashAmount(userId);
        const amount = await getUserFinancialSummary(userId);
        // setUserCashAmount(amount[0]);
        setUserCashAmount(amount);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unexpected error occurred');
        }
      }
    };

    fetchUserCashAmount();
  }, []);

  return (
    <div className="d-flex justify-content-center " >
      <div style={{ width: "400px", height: "400px", position: "relative" }}>
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={120}
            outerRadius={160}
            fill={BLUE_COLOR}
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
            //   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            <Cell key={`cell-${index}`} fill={BLUE_COLOR} />

            ))}
          </Pie>
          {/* <Tooltip /> */}
          {/* <Legend /> */}
        </PieChart>
        <div
          className="container position-absolute"
          style={{
            top: "50%",
            left: "70%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <div className="row g-0">
            <div className="col p-0" style={{flex: 0.3}}>
              <p style={{ margin: 0, color: "#125f3d" }}>Toplam Nakit</p>
              {/* <p style={{ margin: 0, color: "#125f3d" }}>1000&nbsp;₺</p> */}
              <p style={{ margin: 0, color: "#125f3d" }}>{userAmount?.totalCash !== null ? `${userAmount?.totalCash} ₺` : "Yükleniyor..."}</p>
            </div>
            <div className="col p-0" style={{flex: 0.3}}>
              <p style={{ margin: 0, color: "#f31137" }}>Toplam Borç</p>
              <p style={{ margin: 0, color: "#f31137" }}>{userAmount?.totalDebt !== null ? `${userAmount?.totalDebt} ₺` : "Yükleniyor..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonutGraffice;
