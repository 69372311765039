import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Customer {
    firstName: string;
    lastName: string;
    nakit: string;
    islem: string;
    vt: string;
    at: string;
  }
interface Customers {
  type: string;
  customerName: string;
  customerSurname: string;
  debtAmount: string;
  debtCurrency: string;
  id: number;
  debtIssuanceDate: string;
  debtRepaymentDate: string;
  }
  interface CustomerCardProps {
    customer: Customers;
    islem: string;
  }
const CustomerCard:React.FC<CustomerCardProps> = ({ customer, islem }) => {
  const navigate = useNavigate();

    const formattedIssuanceDate = new Date(customer.debtIssuanceDate).toLocaleDateString('tr-TR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  
    const formattedRepaymentDate = new Date(customer.debtRepaymentDate).toLocaleDateString('tr-TR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const firstLetter = customer.customerName.charAt(0).toUpperCase();

    
    let islemColor: string;
    if(customer.type === "Debt"){
        islemColor = "#10853e";
        islem = "verdim"
    }
    else{
        islemColor ="#f21236";
        islem= "aldım"
    }
    const handleDetay = (customer: Customers) => {
      console.log(customer.customerName)
      navigate("/debtDetail" , { state: { customer } });
    }
   
  return (
    <Container
      className="my-4 p-4 position-relative"
      style={{
        borderRadius: "7px",
        backgroundColor: "#ffff",
        border: "2px solid #2895fe",
        maxWidth: "530px",
        width: "100%",
        height: "6rem",
        margin: "0 auto",
      }}
      onClick={() => handleDetay(customer)}
    >
      <Row className="h-100 align-items-center">
        <Col   xs="auto" // Genişliği otomatik ayarla
          className="d-flex align-items-center justify-content-center position-relative "
          style={{ marginRight: "-3rem" }} >
        <div
            style={{
              width: "3rem",
              height: "3rem",
              borderRadius: "50%",
              backgroundColor: "#2895fe",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom:"2rem"
            }}
          >
            {firstLetter}
          </div>
        </Col>
        <Col>
          <Row>
            <Card.Body className="text-center">
              <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
                {customer.customerName} {customer.customerSurname}
              </Card.Title>

            </Card.Body>
          </Row>
          <Row className="d-flex justify-content-center ">
            <Col style={{ marginLeft: '30%'}} className="d-flex justify-content-between mt-4 ml-5">
              <Card.Text className="text-center d-flex">
                <span style={{ marginRight: "0.5rem", fontSize: "1rem" }}>
                  V.T:
                </span>
                <span style={{ fontSize: "1rem", marginRight:"2rem" }}>{formattedIssuanceDate}</span>
              </Card.Text>
              <Card.Text className="text-center d-flex">
                <span style={{ marginRight: "0.5rem", fontSize: "1rem" }}>
                  A.T:
                </span>
                <span style={{ fontSize: "1rem" }}>{formattedRepaymentDate}</span>
              </Card.Text>
            </Col>
          </Row>
        </Col>
        <Col  className="d-flex flex-column align-items-end mb-4 ml-auto" >
          <Row style={{ right: '0'}} className="w-100 ">
            <Card.Text className="text-center" style={{ color: islemColor }}>
              <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
                {customer.debtAmount}
              </Card.Title>
            </Card.Text>
          </Row>
          <Row style={{ right: '0'}}  className="w-100">
            <Card.Text className="text-center" style={{ color: "#d5cbcc" }}>
              <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
                {islem}
              </Card.Title>
            </Card.Text>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerCard;
