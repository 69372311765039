import React, { useEffect, useState } from "react";
// import "./debt.css";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import BottomBar from "../../component/appBar/BottomBar";
import { FaUser } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { getCustomerList,addDebt } from "../../api/customer";
import { Customer, Debt } from "../../types/customerType";

type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;
const AddDebt: React.FC = () => {
  const [debtCurrency, setDebtCurrency] = useState<string>("TL");
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phone: "",
    cashAmount: "",
    debtDate: "",
    repaymentDate: "",
    debtCurrency:"TL"
  });
  const userId = 1;

  const location = useLocation();
  const customer = location.state?.customer;

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCustomerList(userId);
        setCustomers(data);
      } catch (error) {
        console.error("Failed to fetch customers", error);
      }
    }
    fetchData();
  }, [userId]);

  const handleParaBirimiSelect = (eventKey: string | null) => {
    if (eventKey) {
      setDebtCurrency(eventKey);
      setFormData((prevFormData) => ({
        ...prevFormData,
        debtCurrency: eventKey,
      }));
    }
  };
  const handleCustomerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(e.target.value, 10);
    const foundCustomer =
      customers.find((cust) => cust.id === selectedId) || null;
    setSelectedCustomer(foundCustomer);

    if (foundCustomer) {
      setFormData({
        name: foundCustomer.clientName,
        surname: foundCustomer.clientSurname,
        phone: foundCustomer.clientPhone,
        cashAmount: formData.cashAmount,
        debtDate: formData.debtDate,
        repaymentDate: formData.repaymentDate,
        debtCurrency: formData.debtCurrency
      });
      const selectedCustomerUserId = foundCustomer.id
      console.log("seçilen kullanıcının veritabanında ki id si ",selectedCustomerUserId);
    } else {
      setFormData({
        name: "",
        surname: "",
        phone: "",
        cashAmount: formData.cashAmount,
        debtDate: formData.debtDate,
        repaymentDate: formData.repaymentDate,
        debtCurrency: formData.debtCurrency
      });
    }
  };
  const handleInputChange = (e: React.ChangeEvent<FormControlElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const debtData: Debt = {
      debtAmount: parseInt(formData.cashAmount), 
      debtCurrency: formData.debtCurrency,
      debtorId: selectedCustomer ? selectedCustomer.id : 0,
      creditorId: userId, 
      debtIssuanceDate: new Date(formData.debtDate), 
      debtRepaymentDate: new Date(formData.repaymentDate)
    };
    try {
      const {response} = await addDebt(debtData);
      console.log("formdata ekrana yazdırıldı",response);
    } catch (error) {
      console.error("registration failed", error);
    }
  };


  return (
    <div className="d-flex flex-column min-vh-100">
      <WhiteTopBar text="Borç Ekle" />
      <Container
        className="flex-grow-1 d-flex justify-content-center align-items-center"
        style={{ marginTop: "2rem", overflowY: "auto" }}
      >
        <Card
          className="w-100"
          style={{ maxWidth: "500px", borderRadius: "2rem" }}
        >
          <Card.Header
            className="bg-danger text-white text-center position-relative"
            style={{ padding: "3rem 1rem", backgroundColor: "#f31137" }}
          >
            <FaUser style={{ fontSize: "5vh", color: "#f6f6f6" }} />
          </Card.Header>
          <Card.Body className="bg-light">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Select onChange={handleCustomerChange}>
                  <option value="">Müşteri Adı</option>
                  {customers.map((customer: Customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.clientName} {customer.clientSurname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="surname">
                <Form.Control type="text" placeholder="Müşteri Soyad"
                     value={formData.surname}
                     name="surname"
                     />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="phone">
                <Form.Control
                  type="tel"
                  placeholder="Müşteri Telefon"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="cashAmount">
                <Form.Control
                  type="text"
                  name="cashAmount"
                  placeholder="Nakit Miktarı"
                  value={formData.cashAmount}
                  onChange={handleInputChange}
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="cashAmount">
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="cashAmount"
                    placeholder="Nakit Miktarı"
                    value={formData.cashAmount}
                    onChange={handleInputChange}
                  />
                  <DropdownButton
                    variant="outline-secondary"
                    title={debtCurrency}
                    id="input-group-dropdown-1"
                    onChange={handleInputChange}
                    onSelect={handleParaBirimiSelect}
                  >
                    <Dropdown.Item eventKey="TL">TL</Dropdown.Item>
                    <Dropdown.Item eventKey="Dollar">Dollar</Dropdown.Item>
                    <Dropdown.Item eventKey="Euro">Euro</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="debtDate">
                <Form.Label>Borç Veriliş Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="debtDate"
                  value={formData.debtDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="repaymentDate">
                <Form.Label>Borç Geri Ödeme Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="repaymentDate"
                  value={formData.repaymentDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button variant="danger" type="submit">
                  Borç Ver
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div style={{ height: "5rem" }}></div>
      <BottomBar />
    </div>
  );
};

export default AddDebt;
