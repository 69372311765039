import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import MoneyTransferCard from './MoneyTransferCard';
import { cashReceivableList, getMoneyTransfers } from '../../../api/customer';

const MoneyTransferList = () => {
    const navigate = useNavigate();
    const [customerss, setCustomers] = useState([]);

    useEffect(() => {
      const fetchCustomers = async () => {
        try {
          const customerData = await getMoneyTransfers(1);
          console.log("money veritabanından çekilen değer ekrana yazdırıldı: ", customerData);
          setCustomers(customerData);
        } catch (error) {
          console.error("Müşteriler yüklenirken hata oluştu:", error);
        }
      };
  
      fetchCustomers();
    }, []);
    const handleAddCustomerClick = () => {
        navigate("/moneyTransfer");
      };
      // const customers = [
      //   {
      //     giver: {
      //       firstName: "Ahmet",
      //       lastName: "Yılmaz",
      //     },
      //     receiver: {
      //       firstName: "Ayşe",
      //       lastName: "Kara",
      //     },
      //     amount: "500 ₺",
      //     status: "transfer edildi",
      //     at: "28.01.2000",
      //     transferDate: "20.01.2000",
      //   },
      //   {
      //     giver: {
      //       firstName: "Mehmet",
      //       lastName: "Demir",
      //     },
      //     receiver: {
      //       firstName: "Zeynep",
      //       lastName: "Çelik",
      //     },
      //     amount: "750 ₺",
      //     status: "transfer edildi",
      //     at: "15.02.2000",
      //     transferDate: "10.02.2000",
      //   },
      //   {
      //     giver: {
      //       firstName: "Elif",
      //       lastName: "Yurt",
      //     },
      //     receiver: {
      //       firstName: "Ali",
      //       lastName: "Öztürk",
      //     },
      //     amount: "200 ₺",
      //     status: "transfer edildi",
      //     at: "05.03.2000",
      //     transferDate: "01.03.2000",
      //   },
      //   {
      //     giver: {
      //       firstName: "Ahmet",
      //       lastName: "Yılmaz",
      //     },
      //     receiver: {
      //       firstName: "Merve",
      //       lastName: "Yıldız",
      //     },
      //     amount: "1000 ₺",
      //     status: "transfer edildi",
      //     at: "22.04.2000",
      //     transferDate: "18.04.2000",
      //   },
      //   {
      //     giver: {
      //       firstName: "Seda",
      //       lastName: "Kaya",
      //     },
      //     receiver: {
      //       firstName: "Kaan",
      //       lastName: "Aydın",
      //     },
      //     amount: "350 ₺",
      //     status: "transfer edildi",
      //     at: "10.05.2000",
      //     transferDate: "08.05.2000",
      //   },
      // ];
  return (
    <div style={{ marginBottom: "5rem", width: "100%" }}>
    {customerss.map((customer, index) => (
      < MoneyTransferCard key={index} customer={customer} islem='transfer edildi'/>
    ))}
     <Button
       variant="primary"
       style={{
         position: "fixed",
         bottom: "22%",
         right: "6%",
         zIndex: 1000, 
         backgroundColor:"#2755ad",
         borderColor:"#2755ad"
       }}
       onClick={handleAddCustomerClick}
     >
      Tranfer Et
     </Button>
  </div>
  )
}

export default MoneyTransferList
