import React, { useState } from "react";
import { FaChevronLeft, FaUser } from "react-icons/fa";
import "./whitetopBar.css";
import { Container, Row, Col, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useBackButton } from "../../context/backButtonContext";
import ProcessSidebar from "../sideBarr/processSidebar";

interface WhiteTopBarProps {
  text: string;
  customer: {
    firstName: string;
    lastName: string;
    nakit: string;
    islem: string;
    vt: string;
    at: string;
  };
  // onBack: ()=> void;
}
const WhiteTopBarDetail: React.FC<WhiteTopBarProps> = ({ text, customer }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(true);

  const navigate = useNavigate();
  const { handleBackClick } = useBackButton();
console.log(customer);
  const toggleDropdown = () => {
    console.log("burona tıklandı");
    setDropdownOpen(!dropdownOpen);
  };

  const handleDebtDetail = () => {
    navigate("/addDebt");
  };
  return (
    <div className="white-topbar">
      <div className="left-section" onClick={handleBackClick}>
        <FaChevronLeft className="arrow-icon" />
      </div>
      <div className="center-section">
        <span className="text">{text}</span>
      </div>
      <div
        className="right-section me-1"
        style={{ color: "#2895fe" }}
      >
       <Dropdown>
          <Dropdown.Toggle 
            variant="link" 
            id="user-dropdown"
            style={{ background: "none", border: "none", padding: 0 }} 
          >
            <FaUser size={34} style={{ color: "#2895fe" }} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item 
              onClick={() => navigate("/addDebt", { state: { customer } })}
            >
              Borç Ver
            </Dropdown.Item>
            <Dropdown.Item 
              onClick={() => navigate("/ashreceivable", { state: { customer } })}
            >
              Borç Al
            </Dropdown.Item>
            <Dropdown.Item 
              onClick={() => navigate("/moneyTransfer", { state: { customer } })}
            >
              Nakit Gönder
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default WhiteTopBarDetail;

{/* <ProcessSidebar isOpen={dropdownOpen} toggleProcessSidebar={toggleDropdown}/> */}