import React from 'react';
import SignInForm from '../../component/auth/SignInForm';

const SignInPage: React.FC = () => {
  return (
    <div>
      <SignInForm />
    </div>
  );
};

export default SignInPage;
