import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

interface Customer {
  nakit: string;
  islem: string;
  saat: string;
  tarih: string;
}
interface Customers {
  type: string;
  customerName: string;
  customerSurname: string;
  debtAmount: string;
  debtCurrency: string;
  debtIssuanceDate: string;
  debtRepaymentDate: string;
  id: number;
  totalReceivables : number;
  toatlDebts: number;
  difference: number;
}
interface CustomerCardProps {
  customer: Customers;
}
const DebtDetailCard: React.FC<CustomerCardProps> = ({ customer }) => {
  const navigate = useNavigate();
  let islemIcon: JSX.Element;
  let islemColor: string;
  let islem : string;
  if (customer.type === "Debt") {
    islemColor = "#f21236";
    islemIcon = <FaArrowUp size={24} />;
    islem = "verdim"
  } else {
    islemIcon = <FaArrowDown size={24} />;
    islemColor = "#10853e";
    islem= "aldım";
  }
  const formattedIssuanceDate = new Date(customer.debtIssuanceDate).toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const time = new Date(customer.debtIssuanceDate).toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit' });


  const formattedRepaymentDate = new Date(customer.debtRepaymentDate).toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  return (
    <Container
      className="my-4 p-4 position-relative"
      style={{
        borderRadius: "2rem",
        backgroundColor: "#ffff",
        border: "2px solid #2895fe",
        maxWidth: "530px",
        width: "100%",
        height: "6rem",
        margin: "0 auto",
      }}
    >
      <Row className="h-100 align-items-center">
        <Col
          xs="auto" // Genişliği otomatik ayarla
          className="d-flex align-items-center justify-content-center position-relative "
          style={{ marginRight: "-3rem" }}
        >
          <div
            style={{
              width: "3rem",
              height: "3rem",
              borderRadius: "50%",
              backgroundColor: "#2895fe",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "2rem",
            }}
          >
            {islemIcon}
          </div>
        </Col>
        <Col>
          <Row>
            <Col>
              <Card.Body className="text-center mb-5 ms-5">
                <Card.Title style={{ margin: 0 }}>
                  {formattedIssuanceDate}
                </Card.Title>
              </Card.Body>
            </Col>
            <Col>
              <Card.Body className="text-center mb-2">
                <Card.Title style={{ margin: 0 }}>
                  {time}
                </Card.Title>
              </Card.Body>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex flex-column align-items-end mb-4 ml-auto p-0" >
          <Row style={{ right: "0" }} className="w-100 ">
            <Card.Text className="text-center" style={{ color: islemColor }}>
              <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
                {customer.debtAmount}
              </Card.Title>
            </Card.Text>
          </Row>
          <Row style={{ right: "0" }} className="w-100">
            <Card.Text className="text-center" style={{ color: "#d5cbcc" }}>
              <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
                {islem}
              </Card.Title>
            </Card.Text>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DebtDetailCard;
