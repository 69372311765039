import React, { useState } from "react";
import "./transfer.css";
import {
  Container,
  Card,
  Form,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { FaChevronRight, FaUser } from "react-icons/fa";
import { useCustomerForm } from "../../hooks/useCustomerForms";
import { MoneyTransfer } from "../../types/customerType";
import { moneyTransfer } from "../../api/customer";

interface MoneyTransferFirstStepProps {
  onNext: () => void;
}
// const MoneyTransferFirstStep: React.FC<MoneyTransferFirstStepProps> = ({
// onNext,
const MoneyTransferFirstStep = () => {
  const [paraBirimi, setParaBirimi] = useState<string>("TL");
  const userId = 1;
  const {
    customers,
    transferFormData,
    selectedCustomer,
    receiverCustomer,
    handleMoneyTransferInputChange,
    handleMoneyTransferCustomerChange,
    handleSenderCustomerChange,
  } = useCustomerForm(userId);

  const handleParaBirimiSelect = (eventKey: string | null) => {
    if (eventKey) {
      setParaBirimi(eventKey);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const moneyTransferData: MoneyTransfer = {
      receivedAmount: parseInt(transferFormData.receivedAmount),
      moneyCurrency: transferFormData.moneyCurrency,
      senderId: selectedCustomer ? selectedCustomer.id : 0,
      receiverId: receiverCustomer ? receiverCustomer.id : 0,
      intermediaryId: userId,
      receivedDate: new Date(transferFormData.receivedDate),
      transferDate: new Date(transferFormData.transferDate),
    };
    console.log("form data ekrana yazdırıldı: ", moneyTransferData);
    try {
      const {response} = await moneyTransfer(moneyTransferData);
      console.log("formdata ekrana yazdırıldı",response);
    } catch (error) {
      console.error("registration failed", error);
    }
  };
  return (
    <div className="d-flex flex-column min-vh-100">
      <Container
        className="flex-grow-1 d-flex justify-content-center align-items-center"
        style={{ marginTop: "2rem", overflowY: "auto" }}
      >
        <Card
          className="w-100"
          style={{ maxWidth: "400px", borderRadius: "1rem" }}
        >
          <Card.Header
            className="text-white text-center position-relative"
            style={{ padding: "2rem 1rem", backgroundColor: "#2755ad" }}
          >
            <div className="form-transfer-section form-transfer-section-top">
              <FaUser style={{ fontSize: "5vh", color: "#f6f6f6" }} />
            </div>
          </Card.Header>
          <Card.Body className="bg-light">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Gönderen Müşteri</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    handleSenderCustomerChange(parseInt(e.target.value))
                  }
                >
                  <option value="">Gönderen Müşteri</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.clientName} {customer.clientSurname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Control
                  type="tel"
                  placeholder="Müşteri Telefon"
                  name="phone"
                  value={transferFormData.phone}
                  onChange={handleMoneyTransferInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="receivedDate">
                <Form.Label>Nakit Alış Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="receivedDate"
                  value={transferFormData.receivedDate}
                  onChange={handleMoneyTransferInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="receivedAmount">
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="receivedAmount"
                    placeholder="Nakit Miktarı"
                    value={transferFormData.receivedAmount}
                    onChange={handleMoneyTransferInputChange}
                  />
                  <DropdownButton
                    variant="outline-secondary"
                    title={transferFormData.moneyCurrency}
                    id="input-group-dropdown-1"
                    onChange={handleMoneyTransferInputChange}
                    onSelect={handleParaBirimiSelect}
                  >
                    <Dropdown.Item eventKey="TL">TL</Dropdown.Item>
                    <Dropdown.Item eventKey="Dollar">Dollar</Dropdown.Item>
                    <Dropdown.Item eventKey="Euro">Euro</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="name">
                {/* <Form.Select onChange={(e) => handleCustomerChange(parseInt(e.target.value))}> */}
                <Form.Label>Gönderen Müşteri</Form.Label>
                <Form.Select 
                 onChange={(e) =>
                  handleMoneyTransferCustomerChange(parseInt(e.target.value))
                }>
                  <option value="">Alıcı Müşteri</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.clientName} {customer.clientSurname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="transferDate">
                <Form.Label>Nakit Gönderim Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="transferDate"
                  value={transferFormData.transferDate}
                  onChange={handleMoneyTransferInputChange}
                />
              </Form.Group>

              {/* <div className="d-flex justify-content-center" >
           
                <FaChevronRight className="arrow-transfer-icon" onClick={onNext} 
                style={{
                  right:"0.5rem"
                }}/>
              </div> */}
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  Gönder
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div style={{ height: "5rem" }}></div>
    </div>
  );
};

export default MoneyTransferFirstStep;
