import React from 'react'
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

interface FormProps {
  namePlaceholder: string;
  surnamePlaceholder: string;
  phonePlaceholder: string;
  addressPlaceholder: string;
}

const AccountInfoForm: React.FC<FormProps> = ({
  namePlaceholder,
  surnamePlaceholder,
  phonePlaceholder,
  addressPlaceholder,
}) => {
  return (
    <div className="d-flex flex-column ">
    <Container
      className="d-flex justify-content-center align-items-center flex-grow-1"
      style={{ maxWidth: "500px", width: "80%" }}
    >
      <div
        className="border rounded-3 overflow-hidden"
        style={{ height: "75vh", width:'50vh', marginTop:'2rem' }}
      >
        <div
          className="bg-primary text-white text-center d-flex align-items-center justify-content-center"
          style={{ height: "40%", position: "relative",backgroundColor: '2755ad'}}
        >
          <Image
            src="/profilresmi.jpg"
            alt="Profile"
            roundedCircle
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "3px solid white",
            }}
          />
        </div>
        <div
          className="bg-light d-flex justify-content-center align-items-center"
          style={{ height: "60%" }}
        >
          <Form className="w-75">
            <Form.Group controlId="formName" className="mb-3">
              <Form.Control
                type="text"
                placeholder={namePlaceholder}
                className="border-primary"
              />
            </Form.Group>
            <Form.Group controlId="formSurname" className="mb-3">
              <Form.Control
                type="text"
                placeholder={surnamePlaceholder}
                className="border-primary"
              />
            </Form.Group>
            <Form.Group controlId="formPhone" className="mb-3">
              <Form.Control
                type="tel"
                placeholder={phonePlaceholder}
                className="border-primary"
              />
            </Form.Group>
            <Form.Group controlId="formAddress" className="mb-3">
              <Form.Control
                type="text"
                placeholder={addressPlaceholder}
                className="border-primary"
              />
            </Form.Group>
          </Form>
        </div>
      </div>
    </Container>
  </div>
  )
}

export default AccountInfoForm
