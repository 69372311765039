import React, { useState } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  DropdownButton,
  InputGroup,
  Dropdown,
  Alert,
} from "react-bootstrap";
import { FaChevronRight, FaUser } from "react-icons/fa";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import BottomBar from "../../component/appBar/BottomBar";
import { useCustomerForm } from "../../hooks/useCustomerForms";
import { addCashReceivable, addDebt } from "../../api/customer";
import { Debt } from "../../types/customerType";

const CashReceivable = () => {
  const [paraBirimi, setParaBirimi] = useState<string>("TL");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const userId = 1;
  const {
    customers,
    formData,
    selectedCustomer,
    setFormData,
    handleCustomerChange,
    setSelectedCustomer,
    handleInputChange,
  } = useCustomerForm(userId);

  const handleParaBirimiSelect = (eventKey: string | null) => {
    if (eventKey) {
      setParaBirimi(eventKey);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const debtData: Debt = {
      debtAmount: parseInt(formData.cashAmount),
      debtCurrency: formData.debtCurrency,
      debtorId: userId,
      creditorId: selectedCustomer ? selectedCustomer.id : 0,
      debtIssuanceDate: new Date(formData.debtDate),
      debtRepaymentDate: new Date(formData.repaymentDate),
    };
    try {
      const response = await addCashReceivable(debtData);
      setSuccessMessage(response);
      setFormData({
        name: "",
        surname: "",
        phone: "",
        cashAmount: "",
        debtDate: "",
        repaymentDate: "",
        debtCurrency: "TL",
      });

      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);
    } catch (error) {
      console.error("registration failed", error);
    }
  };

  return (
    <div className="d-flex flex-column ">
      <WhiteTopBar text="Borç Al" />
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Container
        className="flex-grow-1 d-flex justify-content-center align-items-center"
        style={{ marginTop: "2rem", overflowY: "auto" }}
      >
        <Card
          className="w-100"
          style={{ maxWidth: "400px", borderRadius: "1rem" }}
        >
          <Card.Header
            className="text-white text-center position-relative"
            style={{ padding: "3rem 1rem", backgroundColor: "#2755ad" }}
          >
            <div className="form-transfer-section form-transfer-section-top">
              <FaUser style={{ fontSize: "5vh", color: "#f6f6f6" }} />
            </div>
          </Card.Header>
          <Card.Body className="bg-light">
            <Form onSubmit={handleSubmit}>
              {/* <Form.Group className="mb-3" controlId="name">
                <Form.Control type="text" placeholder="Ad" />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="name">
                <Form.Select
                  onChange={(e) =>
                    handleCustomerChange(parseInt(e.target.value))
                  }
                >
                  <option value="">Müşteri Adı</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.clientName} {customer.clientSurname}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="surname">
                <Form.Control type="text" placeholder="Soyad" />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="phone">
                <Form.Control
                  type="tel"
                  placeholder="Müşteri Telefon"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="cashAmount">
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="cashAmount"
                    placeholder="Nakit Miktarı"
                    value={formData.cashAmount}
                    onChange={handleInputChange}
                  />
                  <DropdownButton
                    variant="outline-secondary"
                    title={formData.debtCurrency}
                    id="input-group-dropdown-1"
                    onChange={handleInputChange}
                    onSelect={handleParaBirimiSelect}
                  >
                    <Dropdown.Item eventKey="TL">TL</Dropdown.Item>
                    <Dropdown.Item eventKey="Dollar">Dollar</Dropdown.Item>
                    <Dropdown.Item eventKey="Euro">Euro</Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="debtDate">
                <Form.Label>Nakit Alım Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="debtDate"
                  value={formData.debtDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="repaymentDate">
                <Form.Label>Geri Ödeme Tarihi</Form.Label>
                <Form.Control
                  type="date"
                  name="repaymentDate"
                  value={formData.repaymentDate}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  Borç Al
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <BottomBar />
      <div style={{ height: "5rem" }}></div>
    </div>
  );
};

export default CashReceivable;
