import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import CashPayblesCard from './CashPayblesCard';
import { useNavigate } from 'react-router-dom';
import { cashReceivableList } from '../../../api/customer';

const CashPayblesList = () => {
    const navigate = useNavigate();
    const [customerss, setCustomers] = useState([]);
    useEffect(() => {
      const fetchCustomers = async () => {
        try {
          const customerData = await cashReceivableList(1);
          console.log("veritabanından çekilen değer ekrana yazdırıldı: ", customerData);
          setCustomers(customerData);
        } catch (error) {
          console.error("Müşteriler yüklenirken hata oluştu:", error);
        }
      };
  
      fetchCustomers();
    }, []);
    
    const handleAddCustomerClick = () => {
        navigate("/cashreceivable");
      };
  // const customers = [
  //   {
  //     firstName: "Ahmet",
  //     lastName: "Yılmaz",
  //     nakit: "500₺ ",
  //     islem: "verdim",
  //     vt: "28.01.2000",
  //     at: "20.01.2000",
  //   },
  //   {
  //     firstName: "Ayşe",
  //     lastName: "Kara",
  //     nakit: "1000₺ ",
  //     islem: "aldım",
  //     vt: "17.01.2000",
  //     at: "17.01.2000",
  //   },
  //   {
  //     firstName: "Mehmet",
  //     lastName: "Demir",
  //     nakit: "600₺ ",
  //     islem: "verdim",
  //     vt: "20.01.2000",
  //     at: "20.01.2000",
  //   },
  // ];
  return (
    <div style={{ marginBottom: "5rem", width: "100%" }}>
    {customerss.map((customer, index) => (
      < CashPayblesCard key={index} customer={customer}  islem='aldım'/>
    ))}
     <Button
       variant="primary"
       style={{
         position: "fixed",
         bottom: "22%",
         right: "6%",
         zIndex: 1000, 
         backgroundColor:"#13603c",
         borderColor:"#13603c"
       }}
       onClick={handleAddCustomerClick}
     >
       Nakit Al
     </Button>
  </div>
  )
}

export default CashPayblesList
