import React, { useState } from "react";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import { Container } from "react-bootstrap";
import BottomBar from "../../component/appBar/BottomBar";

const languages = ["Türkçe", "İngilizce", "Fransızca", "Almanca", "İspanyolca"];

const Language = () => {
  const [activeLanguage, setActiveLanguage] = useState("Türkçe");

  const getContainerStyle = (language: string) => ({
    backgroundColor: activeLanguage === language ? "#2895fe" : "#ffffff",
    border: activeLanguage === language ? "none" : "2px solid #2895fe",
    color: activeLanguage === language ? "#ffffff" : "#2895fe",
  });
  return (
    <div>
      <WhiteTopBar text="Language" />
      {languages.map((language) => (
        <Container
          key={language}
          className="my-4 p-4 position-relative"
          style={{
            borderRadius: "10px",
            maxWidth: "500px",
            width: "60%",
            height: "3.5rem",
            margin: "0 auto",
            ...getContainerStyle(language),
          }}
          onClick={() => setActiveLanguage(language)}
        >
          <div
            className="position-absolute top-50 start-50 translate-middle"
            style={{ fontSize: "1.5rem" }}
          >
            {language}
          </div>
        </Container>
      ))}
      <BottomBar />
    </div>
  );
};

export default Language;
