import React from "react";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import BottomBar from "../../component/appBar/BottomBar";
import { Container, Form, Button, Row, Col } from "react-bootstrap";

const Help: React.FC = () => {
  return (
    <div>
      <WhiteTopBar text="HELP" />
      <Container
        className="my-4 p-4"
        style={{
          backgroundColor: "#2895fe",
          borderRadius: "2rem",
          maxWidth: "600px",
          width: "70%",
          margin: "0 auto",
        }}
      >
        <h2 className="text-center mb-4">Bize Ulaşın</h2>
        <Form>
          <Form.Group controlId="formBasicName" className="mb-3">
            <Form.Control type="text" placeholder="Adınızı girin" />
          </Form.Group>

          <Form.Group controlId="formBasicEmail" className="mb-3">
            <Form.Control type="email" placeholder="Email adresinizi girin" />
          </Form.Group>

          <Form.Group controlId="formBasicSubject" className="mb-3">
            <Form.Control type="text" placeholder="Konu başlığını girin" />
          </Form.Group>

          <Form.Group controlId="formBasicMessage" className="mb-4">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Mesajınızı buraya yazın"
            />
          </Form.Group>

          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              type="submit"
              style={{ borderRadius: "10px" }}
            >
              Gönder
            </Button>
          </div>
        </Form>
      </Container>
      <Container
        className="p-4"
        style={{
          backgroundColor: "#fff",
          border: "2px solid #2895fe",
          borderRadius: "0.5rem",
          maxWidth: "550px",
          width: "70%",
          height: "5rem",
        }}
      >
        <Row style={{ marginLeft: "calc(-.9 * var(--bs-gutter-x))" }}>
        <Col xs={6}>
            <p style={{ margin: "0", fontSize: "15px" }}>Müşteri Hizmetleri:</p>
          </Col>
          <Col xs={6}>
            <p style={{ margin: "0", fontSize: "15px" }}>
              <a href="mailto:tkn.ism200@gmail.com">tkn.ism200@gmail.com</a>
            </p>
          </Col>
        </Row>
        <Row style={{ marginLeft: "calc(-.9 * var(--bs-gutter-x))" }}>
          <Col xs={6}>
            <p style={{ margin: "0", fontSize: "15px" }}>Bize Ulaşın:</p>
          </Col>
          <Col xs={6}>
            <p style={{ margin: "0", fontSize: "15px" }}>
              <a href="tel:+9055114888516">55114888516</a>
            </p>
          </Col>
        </Row>
      </Container>
      <BottomBar />
    </div>
  );
};

export default Help;
