import React from 'react';
import './sideBar.css';
import { Link } from 'react-router-dom';
import { FaClock, FaLanguage, FaList, FaQuestionCircle, FaUser } from 'react-icons/fa';

interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>X</button>
        <div className="profile-container">
        <img src="/profilresmi.jpg" alt="Profile" className="profile-pic" />
      </div>
        <nav className="sidebar-nav">
          <Link to="/accountInfo" className="sidebar-item">
            <FaUser className="icon" />
            <span>Hesap Bilgileri</span>
          </Link>
          <Link to="/account-movements" className="sidebar-item">
            <FaList className="icon" />
            <span>Hesap Hareketleri</span>
          </Link>
          <Link to="/sidebar/clock" className="sidebar-item">
            <FaClock className="icon" />
            <span>Saat ve Tarih</span>
          </Link>
          <Link to="/sidebar/language" className="sidebar-item">
            <FaLanguage className="icon" />
            <span>Dil</span>
          </Link>
          <Link to="/sidebar/help" className="sidebar-item">
            <FaQuestionCircle className="icon" />
            <span>Yardım</span>
          </Link>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;