import React, { useState } from "react";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import { Container } from "react-bootstrap";

const Clock = () => {
  // Seçili olan container'ın formatını tutacak state
  const [activeFormat, setActiveFormat] = useState<'24' | '12'>('24');

  // Container'ın stilini belirleyen fonksiyon
  const getContainerStyle = (format: '24' | '12') => ({
    backgroundColor: activeFormat === format ? "#2895fe" : "#ffffff",
    border: activeFormat === format ? "none" : "2px solid #2895fe",
    color: activeFormat === format ? "#ffffff" : "#2895fe",
  });

  return (
    <div>
      <WhiteTopBar text="Clock&History" />
      <Container
        className="my-4 p-4 position-relative"
        style={{
          borderRadius: "10px",
          maxWidth: "500px",
          width: "60%",
          height: "3.5rem",
          margin: "0 auto",
          ...getContainerStyle('24'), // Stil ayarlarını uygula
        }}
        onClick={() => setActiveFormat('24')}
      >
        <div
          className="position-absolute top-5 start-30 translate-middle-y pb-3"
          style={{ textAlign: "center", fontSize: "1.5rem" }}
        >
          24 Saat Formatı
        </div>
        <div
          className="position-absolute  start-30  pt-1"
          style={{ textAlign: "right" }}
        >
          Zaman Göstergesi : 15:00
        </div>
      </Container>
      <Container
        className="my-4 p-4 position-relative"
        style={{
          borderRadius: "10px",
          maxWidth: "500px",
          width: "60%",
          height: "3.5rem",
          margin: "0 auto",
          ...getContainerStyle('12'), // Stil ayarlarını uygula
        }}
        onClick={() => setActiveFormat('12')}
      >
        <div
         className="position-absolute  top-5 start-30 translate-middle-y pb-3"
          style={{ textAlign: "center", fontSize: "1.5rem" }}
        >
          12 Saat Formatı
        </div>
        <div
           className="position-absolute  start-30  pt-1"
          style={{ textAlign: "right" }}
        >
          Zaman Göstergesi : 03:00
        </div>
      </Container>
    </div>
  );
};

export default Clock;
