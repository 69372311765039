import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/userContext";
import { BackButtonProvider } from "./context/backButtonContext";
import { BrowserRouter } from "react-router-dom";
import { CustomerProvider } from "./context/customerContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <UserProvider>
      <BackButtonProvider>
        <App />
      </BackButtonProvider>
    </UserProvider>
  </BrowserRouter>
);

reportWebVitals();
