import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { Container, Row, Col, Form, Image, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./customer.css";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import BottomBar from "../../component/appBar/BottomBar";
import { Customer } from "../../types/customerType";
import { addCustomer } from "../../api/customer";
const AddCustomer: React.FC = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [formData, setFormData] = useState<Customer>({
    clientName: 'Kerim',
    clientSurname: 'Araz',
    clientPhone: '551148',
    userId: 1,
    id:1
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("formdata ekrana yazdırıldı", formData);
    try {
      const response = await addCustomer(formData);
      console.log("customer response ekrana yazdırıldı ", response);
     setSuccessMessage(response.message);

     setTimeout(() => {
      setSuccessMessage(null);
    }, 2000);

    } catch (error) {
      console.error("registration failed", error);
    }

  };
  return (
    <div className="d-flex flex-column  ">
      <WhiteTopBar text="Müşteri Ekle" />
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      <Container className="mt-5 justify-content-center">
        <Row className="justify-content-center p-5">
          <Col xs={12} md={8} lg={6}>
            <div className="card w-100 ">
              <div className="card-body text-center ">
                <div
                  className="mx-auto mb-4"
                  style={{
                    width: "80px",
                    height: "80px",
                    backgroundColor: "#2755ad",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaUser style={{ fontSize: "5vh", color: "#f6f6f6" }} />
                </div>
                <Form
                  onSubmit={handleSubmit}
                  style={{ height: "15rem", marginTop: "5rem" }}
                >
                  <Form.Group className="mb-3" controlId="clientName">
                    <Form.Control
                      type="text"
                      name="clientName"
                      placeholder="Ad"
                      value={formData.clientName}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="clientSurname">
                    <Form.Control
                      type="text"
                      name="clientSurname"
                      placeholder="Soyad"
                      value={formData.clientSurname}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="clientPhone">
                    <Form.Control
                      type="tel"
                      placeholder="Telefon"
                      value={formData.clientPhone}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <div className="text-end">
                    <Button variant="primary" type="submit" >Müşteri Ekle</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <BottomBar />
    </div>

  );
};

export default AddCustomer;
