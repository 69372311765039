import React, { useState } from "react";
import MoneyTransferFirstStep from "../../component/transfer/MoneyTransferFirstStep";
import MoneyTransferSecondStep from "../../component/transfer/MoneyTransferSecondStep";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import BottomBar from "../../component/appBar/BottomBar";
import { useNavigate } from "react-router-dom";
import { useBackButton } from "../../context/backButtonContext";


const MoneyTransfer: React.FC = () => {
  const {step, handleNextStep} = useBackButton();
  return( 
        <div>
          <WhiteTopBar text="Nakit Transfer Et"/>
          <MoneyTransferFirstStep />
          {/* {step === 1 ? (
          ) : (
            <MoneyTransferSecondStep />
          )} */}
          <BottomBar />
        </div>
      )
};




// const MoneyTransfer = () => {
//     const [step, setStep] = useState(1); 
//     const navigate = useNavigate();

//     const handleNextStep = () => {
//       setStep(2); 
//     };
//     const handleBackClick = () => {
//         if (step === 2) {
//           setStep(1); 
//         } else {
//           navigate(-1); 
//         }
//       };
//   return( 
//     <div>
//       <WhiteTopBar text="Nakit Transfer Et" onBack={handleBackClick}/>
//       {step === 1 ? (
//         <MoneyTransferFirstStep onNext={handleNextStep} />
//       ) : (
//         <MoneyTransferSecondStep />
//       )}
//       <BottomBar />
//     </div>
//   )
// };

export default MoneyTransfer;
