import React from 'react';
import './topBar.css'; // Özel stil dosyası için
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from 'react-bootstrap';

const TopBar = () => {
  return (
    // <div className="top-bar">
    //   <div className="profile-section">
    //     <img src="/profilresmi.jpg" alt="Profile" className="profile-pic" />
    //   </div>
    // </div>
        <Container fluid className="bg-primary py-2" style={{height:"30%"}} >
        <Row className="align-items-center">
          <Col className="d-flex justify-content-start">
            <Image src="/profilresmi.jpg" alt="Profile" roundedCircle style={{ width: '60px', height: '60px' }} />
          </Col>
        </Row>
      </Container>
  );
};

export default TopBar;
