import React, { createContext, useContext, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface BackButtonContextProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  handleBackClick: () => void;
  handleNextStep: () => void;
}

const BackButtonContext = createContext<BackButtonContextProps | undefined>(undefined);

export const BackButtonProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [step, setStep] = useState<number>(1);
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (step === 2) {
      setStep(1); // Bir önceki adıma geri dön
    } else {
      navigate(-1); // Bir önceki sayfaya geri dön
    }
  };

  const handleNextStep = () => {
    setStep(2);
  };

  return (
    <BackButtonContext.Provider
      value={{
        step,
        setStep,
        handleBackClick,
        handleNextStep,
      }}
    >
      {children}
    </BackButtonContext.Provider>
  );
};

export const useBackButton = () => {
  const context = useContext(BackButtonContext);
  if (!context) {
    throw new Error("useBackButton must be used within a BackButtonProvider");
  }
  return context;
};
