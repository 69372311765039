import React from "react";
// import "./accountInfo.css";
import TopBar from "../../component/appBar/TopBar";
import BottomBar from "../../component/appBar/BottomBar";
import WhiteTopBar from "../../component/appBar/whiteTopBar";
import AccountInfoForm from "../../component/account/AccountInfoForm";
import AccountInfoDenemeForm from "../../component/account/AccountInfoForm";

const AccountInfo = () => {
  return (
    <div>
      <WhiteTopBar text="Hesap Bilgileri" />
  
      <AccountInfoForm
        namePlaceholder="isim"
        surnamePlaceholder="surname"
        phonePlaceholder="551148"
        addressPlaceholder="dcacscssdcds"
      />
      <BottomBar />
    </div>
  );
};

export default AccountInfo;
