import React from 'react';
import {  FaChevronLeft } from 'react-icons/fa';
import './whitetopBar.css';
import { useNavigate } from "react-router-dom";
import { useBackButton } from '../../context/backButtonContext';

interface WhiteTopBarProps {
  text: string;
  // onBack: ()=> void;
}

const WhiteTopBar: React.FC<WhiteTopBarProps> = ({ text }) => {
  const navigate = useNavigate();
  const {handleBackClick} = useBackButton();


  return (
    <div className="white-topbar">
      <div className="left-section" onClick={handleBackClick}>
        <FaChevronLeft className="arrow-icon" />
      </div>
      <div className="center-section">
        <span className="text">{text}</span>
      </div>
    </div>
  );
};
// const WhiteTopBar: React.FC<WhiteTopBarProps> = ({ text, onBack }) => {
//   const navigate = useNavigate();

//   return (
//     <div className="white-topbar">
//       <div className="left-section" onClick={onBack}>
//         <FaChevronLeft className="arrow-icon" />
//       </div>
//       <div className="center-section">
//         <span className="text">{text}</span>
//       </div>
//     </div>
//   );
// };

export default WhiteTopBar;
