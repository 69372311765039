import React, { useEffect, useState } from 'react';
import { Container, Button, Table, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { activateUser, getInactiveUsers } from '../../api/customer';

interface User {
  name: string;
  surname: string;
  phone: string;
  id: number;
}

const AdminHome = () => {
  const [userData, setUserData] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userId = 1; // Örnek userId, gerçek userId'nizi buraya koyun
        const data = await getInactiveUsers(userId);
        setUserData(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleActivate = async (id: number) => {
    console.log(id);
    try {
     const response =  await activateUser(id);
     setSuccessMessage(response);
     console.log(response);
     const userId = 1 ;
      const data = await getInactiveUsers(userId);
      setUserData(data);

      setTimeout(() => {
        setSuccessMessage(null);
      }, 2000);

    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    }
  };
  if (loading) {
    return (
      <Container className="mt-4">
        <Spinner animation="border" />
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <h2>Admin Dashboard</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {userData.length > 0 ? (
        <Table bordered hover responsive>
          <thead className="table-light">
            <tr>
              <th>id</th>
              <th>Ad</th>
              <th>Soyad</th>
              <th>Numara</th>
              <th>İşlem</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user, index) => (
              <tr key={index}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.surname}</td>
                <td>{user.phone}</td>
                <td>
                  <Button variant="primary" size="sm" onClick={() => handleActivate(user.id)}>Onayla</Button>
                  <Button variant="danger" size="sm" className="ms-2">Sil</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No inactive users found.</p>
      )}
    </Container>
  );
};

export default AdminHome;
