import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import CustomerCard from "./CustomerCard";
import { useNavigate } from "react-router-dom";
import { getCustomerList, homeCustomerList } from "../../api/customer";
const CustomerList = () => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
      const fetchCustomers = async () => {
        try {
          const customerData = await homeCustomerList(1);
          setCustomers(customerData.data);
        } catch (error) {
          console.error("Müşteriler yüklenirken hata oluştu:", error);
        }
      };
  
      fetchCustomers();
    }, []);
    const handleAddCustomerClick = () => {
        navigate("/addCustomer");
      };
  // const customers = [
  //   {
  //     firstName: "Ahmet",
  //     lastName: "Yılmaz",
  //     nakit: "500 ₺",
  //     islem: "verdim",
  //     vt: "28.01.2000",
  //     at: "20.01.2000",
  //   },
  //   {
  //     firstName: "Ayşe",
  //     lastName: "Kara",
  //     nakit: "1000 ₺",
  //     islem: "aldım",
  //     vt: "17.01.2000",
  //     at: "17.01.2000",
  //   },
  //   {
  //     firstName: "Mehmet",
  //     lastName: "Demir",
  //     nakit: "600 ₺",
  //     islem: "verdim",
  //     vt: "20.01.2000",
  //     at: "20.01.2000",
  //   },
  // ];
  return (
    <div style={{ marginBottom: "4rem", width: "100%" }}>
    {customers.map((customer, index) => (
      <CustomerCard key={index} customer={customer} islem=" " />
    ))}
     <Button
       variant="primary"
       style={{
         position: "fixed",
         bottom: "15%",
         right: "5%",
         zIndex: 1000, 
       }}
       onClick={handleAddCustomerClick}
     >
       Müşteri Ekle
     </Button>
  </div>
//      <Container>
//      <Row >
//        {customers.map((customer, index) => (
//          <Col key={index} className="mb-4">
//            <CustomerCard customer={customer} />
//          </Col>
//        ))}
//      </Row>
     
//      <Button
//        variant="primary"
//        style={{
//          position: "fixed",
//          bottom: "20px",
//          right: "20px",
//          zIndex: 1000, 
//        }}
//      >
//        Müşteri Ekle
//      </Button>
//    </Container>
    // <>
    //   <div style={{ marginBottom: "4rem", width: " 530px" }}>
    //     {customers.map((customer, index) => (
    //       <Container
    //         key={index}
    //         className="my-4 p-4 position-relative"
    //         style={{
    //           borderRadius: "7px",
    //           backgroundColor: "#ffff",
    //           border: "2px solid #2895fe",
    //           maxWidth: "530px",
    //           width: "100%",
    //           height: "6rem",
    //           margin: "0 auto",
    //         }}
    //       >
    //         <Row className="h-100 align-items-center">
    //           <Col>
    //             <Row>
    //               <Card.Body className="text-center">
    //                 <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
    //                   {customer.firstName} {customer.lastName}
    //                 </Card.Title>
    //               </Card.Body>
    //             </Row>
    //             <Row>
    //               <Col>
    //                 <Card.Body className="text-center">
    //                   <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
    //                     V.T:
    //                   </Card.Title>
    //                 </Card.Body>
    //               </Col>
    //               <Col>
    //                 <Card.Body className="text-center">
    //                   <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
    //                     {customer.vt}
    //                   </Card.Title>
    //                 </Card.Body>
    //               </Col>
    //               <Col>
    //                 <Card.Body className="text-center">
    //                   <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
    //                     A.T:
    //                   </Card.Title>
    //                 </Card.Body>
    //               </Col>
    //               <Col>
    //                 <Card.Body className="text-center">
    //                   <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
    //                     {customer.at}
    //                   </Card.Title>
    //                 </Card.Body>
    //               </Col>
    //             </Row>
    //           </Col>
    //           <Col>
    //             <Row>
    //               <Card.Body className="text-center">
    //                 <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
    //                   {customer.nakit}
    //                 </Card.Title>
    //               </Card.Body>
    //             </Row>
    //             <Row>
    //               <Card.Body className="text-center">
    //                 <Card.Title style={{ margin: 0, fontSize: "1.25rem" }}>
    //                   {customer.islem}
    //                 </Card.Title>
    //               </Card.Body>
    //             </Row>
    //           </Col>
    //         </Row>
    //       </Container>
    //     ))}
    //   </div>
    // </>
  );
};

export default CustomerList;
