import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DebtCard from './DebtCard';
import { debCustomerList } from '../../../api/customer';

const DebtList = () => {
    const navigate = useNavigate();
    const [customerss, setCustomers] = useState([]);

    useEffect(() => {
      const fetchCustomers = async () => {
        try {
          const customerData = await debCustomerList(1);
          console.log("veritabanından çekilen değer ekrana yazdırıldı: ", customerData);
          setCustomers(customerData);
        } catch (error) {
          console.error("Müşteriler yüklenirken hata oluştu:", error);
        }
      };
  
      fetchCustomers();
    }, []);
    const handleAddCustomerClick = () => {
        navigate("/addDebt");
      };
  const customers = [
    {
      firstName: "Ahmet",
      lastName: "Yılmaz",
      nakit: "500 ₺",
      islem: "verdim",
      vt: "28.01.2000",
      at: "20.01.2000",
    },
    {
      firstName: "Ayşe",
      lastName: "Kara",
      nakit: "1000 ₺",
      islem: "aldım",
      vt: "17.01.2000",
      at: "17.01.2000",
    },
    {
      firstName: "Mehmet",
      lastName: "Demir",
      nakit: "600 ₺",
      islem: "verdim",
      vt: "20.01.2000",
      at: "20.01.2000",
    },
  ];
  return (
    <div style={{ marginBottom: "4rem", width: "100%" }}>
    {customerss.map((customer, index) => (
      < DebtCard key={index} customer={customer} islem='verdim' />
    ))}
     <Button
       variant="primary"
       style={{
         position: "fixed",
         bottom: "15%",
         right: "5%",
         zIndex: 1000, 
         backgroundColor:"#f31137",
         borderColor:"#f31137"
       }}
       onClick={handleAddCustomerClick}
     >
       Borç Ver
     </Button>
  </div>
  )
}

export default DebtList
