import React from 'react'
import WhiteTopBar from '../../component/appBar/whiteTopBar'
import BottomBar from '../../component/appBar/BottomBar'
import { useLocation } from 'react-router-dom'
import WhiteTopBarDetail from '../../component/appBar/whiteTopBarDetail'
import DebtDetailList from '../../component/home/debt/DebtDetailList'

const DebtDetail = () => {
  const location = useLocation();
  const customer = location.state.customer;
  return (
    <div className="d-flex flex-column min-vh-100">
    <WhiteTopBarDetail text={`${customer.customerName} ${customer.customerSurname}` } customer= {customer}/>
      <DebtDetailList customer= {customer} islemList =' ' />
      <BottomBar/>
    </div>
  )
}

export default DebtDetail
