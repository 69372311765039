import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Register } from './type'; // Register tipi burada tanımlı olmalı
import { register } from './authprocces'; // Register işlevi burada tanımlı olmalı
import { useUser } from '../../context/userContext'; // Kullanıcı işlemleri için

const SignUpForm: React.FC = () => {
  const [formData, setFormData] = useState<Register>({
    name: 'Baybars',
    surname: 'Tekin',
    username: 'user1',
    email: 'bybrshan@gmail.com',
    password: '1234',
    phone: '8516',
  });

  const navigate = useNavigate();
  const { handleLogin, handleToken } = useUser(); // Kullanıcı işlemleri için

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type } = e.target;
    const newValue = type === 'number' ? Number(value) : value;

    setFormData(prevState => ({
      ...prevState,
      [id]: newValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.email === '' || formData.password === '') {
      return;
    }
    console.log("register formdata",formData)
    try {
      const { user, token } = await register(formData);
      if (user && token) {
        handleLogin(user);
        handleToken(token);
        navigate('/');
      }
    } catch (error) {
      console.error("Registration failed: ", error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card shadow-lg p-3 mb-5 bg-white rounded">
            <div className="card-body">
              <h3 className="card-title text-center mb-4">Sign Up</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Enter Name
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="surname" className="form-label">
                    Enter Surname
                  </label>
                  <input
                    id="surname"
                    type="text"
                    name="surname"
                    className="form-control"
                    placeholder="Enter your surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Enter Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    name="username"
                    className="form-control"
                    placeholder="Enter your username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Enter Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Enter Phone
                  </label>
                  <input
                    id="phone"
                    type="text"
                    name="phone"
                    className="form-control"
                    placeholder="Enter your phone number"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary">
                    Sign Up
                  </button>
                </div>
              </form>
              <div className="text-center mt-3">
                <Link to="/pages/authentication/forgot-password" className="text-muted">
                  Forgot Password?
                </Link>
              </div>
              <div className="text-center mt-3">
                <p className="text-muted mb-0">
                  Already have an account?{' '}
                  <Link to="/signin" className="text-primary">
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
