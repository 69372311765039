import React from "react";
import { Routes, Route } from "react-router-dom";
// import SignInForm from './component/auth/SignInForm';
// import SignIn from './component/auth/SignIn';
import ProtectedRoute from "./ProtectedRoute";
// import SignUpForm from './component/auth/SignUpForm';
import Home from "./pages/home/Home";
import SignInPage from "./pages/Auth/SignInPage";
import SignUpPage from "./pages/Auth/SigUpPage";
import AccountInfo from "./pages/account/AccountInfoPage";
import AddCustomer from "./pages/customer/AddCustomer";
import AddDebt from "./pages/debt/AddDebt";
import MoneyTransfer from "./pages/transfer/MoneyTransfer";
import Help from "./pages/sidebar/Help";
import Clock from "./pages/sidebar/Clock";
import Language from "./pages/sidebar/Language";
import Account from "./pages/home/Account";
import CashReceivable from "./pages/debt/CashReceivable";
import DebtDetail from "./pages/debt/DebtDetail";
import AdminHome from "./pages/home/AdminHome";

const AppRoutes: React.FC = () => {
  return (
      <Routes>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/adminHome" element={<AdminHome />} />
        {/* <Route path="/" element={<Home />} />
        <Route path="/accountInfo" element={<AccountInfo />} />
        <Route path="/addCustomer" element={<AddCustomer />} />
        <Route path="/addDebt" element={<AddDebt />} />
        <Route path="/debtDetail" element={<DebtDetail />} />
        <Route path="/cashreceivable" element={<CashReceivable />} />
        <Route path="/moneyTransfer" element={<MoneyTransfer />} />
        <Route path="/sidebar/help" element={<Help />} />
        <Route path="/sidebar/clock" element={<Clock />} />
        <Route path="/sidebar/language" element={<Language />} />
        <Route path="/home/account" element={<Account />} /> */}

        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/accountInfo" element={<ProtectedRoute><AccountInfo /></ProtectedRoute>} />
        <Route path="/addCustomer" element={<ProtectedRoute><AddCustomer /></ProtectedRoute>} />
        <Route path="/addDebt" element={<ProtectedRoute><AddDebt /></ProtectedRoute>} />
        <Route path="/debtDetail" element={<ProtectedRoute><DebtDetail /></ProtectedRoute>} />
        <Route path="/cashreceivable" element={<ProtectedRoute><CashReceivable /></ProtectedRoute>} />
        <Route path="/moneyTransfer" element={<ProtectedRoute><MoneyTransfer /></ProtectedRoute>} />
        <Route path="/sidebar/help" element={<ProtectedRoute><Help /></ProtectedRoute>} />
        <Route path="/sidebar/clock" element={<ProtectedRoute><Clock /></ProtectedRoute>} />
        <Route path="/sidebar/language" element={<ProtectedRoute><Language /></ProtectedRoute>} />
        <Route path="/home/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        {/* <Route path="/accountInfo" element={<ProtectedRoute><AccountInfo /></ProtectedRoute>} /> */}
      </Routes>
  );
};

export default AppRoutes;
