import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
interface Person {
  firstName: string;
  lastName: string;
}

// interface Customer {
//   giver: Person;
//   receiver: Person;
//   amount: string;
//   status: string;
//   at: string;
//   transferDate: string;
// }
interface Customers {
  senderName: string;
  senderSurname: string;
  receiverName: string;
  receiverSurname: string;
  receivedAmount: string;
  moneyCurrency: string;
  receivedDate: string;
  transferDate: string;
}
interface CustomerCardProps {
  customer: Customers;
  islem: string;
}
const MoneyTransferCard: React.FC<CustomerCardProps> = ({
  customer,
  islem,
}) => {
  const receivedDate = new Date(customer.receivedDate).toLocaleDateString(
    "tr-TR",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }
  );

  const transferDate = new Date(customer.transferDate).toLocaleDateString(
    "tr-TR",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }
  );
  return (
    <Container
      className="my-4 p-4 position-relative"
      style={{
        borderRadius: "7px",
        backgroundColor: "#ffff",
        border: "2px solid #2755ad",
        maxWidth: "530px",
        width: "100%",
        height: "6rem",
        margin: "0 auto",
      }}
    >
      <Row className="h-100 align-items-center">
        <Col>
          <Row
            className="align-items-center"
            style={{
              marginTop: "-1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Card.Body className="text-center ">
              <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap", // Metnin tek satırda kalmasını sağlar
                    overflow: "hidden", // Taşan içeriği gizler
                    textOverflow: "ellipsis", // Taşan metin yerine üç nokta ekler
                  }}
                >
                  <span style={{ fontWeight: "bold", color: "#d5cbcc" }}>
                    NAKİTİ VEREN:
                  </span>{" "}
                  {customer.senderName} {customer.senderSurname}
                </div>
              </Card.Title>
            </Card.Body>
          </Row>
          <Row className="align-items-center">
            <Card.Body className="text-center ">
              <Card.Title
                style={{
                  margin: "auto",
                  fontSize: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <span style={{ fontWeight: "bold", color: "#d5cbcc" }}>
                    NAKİTİ ALAN:
                  </span>{" "}
                  {customer.receiverName} {customer.receiverSurname}
                </div>
              </Card.Title>
            </Card.Body>
          </Row>
          <Row className="d-flex justify-content-center ">
            <Col
              style={{ marginLeft: "30%" }}
              className="d-flex justify-content-between mt-4 "
            >
              <Card.Text className="text-center d-flex">
                <span style={{ marginRight: "0.5rem", fontSize: "1rem" }}>
                  A.T:
                </span>
                <span style={{ fontSize: "1rem" }}>{receivedDate}</span>
              </Card.Text>
              <Card.Text className="text-center d-flex">
                <span
                  style={{
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                  }}
                >
                  G.T:
                </span>
                <span style={{ fontSize: "1rem" }}>{transferDate}</span>
              </Card.Text>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex flex-column ">
          <Row className="mb-2 d-flex ms-3 ">
            <FaEdit style={{ color: "#007bff" }} />
          </Row>
          <Row className="mb-2 d-flex ms-3">
            <FaTrash style={{ color: "#dc3545" }} />
          </Row>
        </Col>
        <Col className="d-flex flex-column align-items-end mb-4 ml-auto">
          <Row style={{ right: "0" }} className="w-100 ">
            <Card.Text className="text-center" style={{ color: "#2755ad" }}>
              <Card.Title style={{ margin: 0, fontSize: "1rem" }}>
                {customer.receivedAmount}
              </Card.Title>
            </Card.Text>
          </Row>
          <Row style={{ right: "0" }} className="w-100">
            <Card.Text className="text-center" style={{ color: "#d5cbcc" }}>
              <Card.Title style={{ margin: 0, fontSize: "0.75rem" }}>
                {islem}
              </Card.Title>
            </Card.Text>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MoneyTransferCard;
